import React, { useCallback, useEffect, useState } from "react";
import { unstable_useBlocker } from "react-router-dom";
import BlockerModal from "./BlockerModal";
import dayjs from "dayjs";

const BaseBlockerModal = ({
  text,
  hasChange,
  initialValues,
  watch,
  dirtyFields,
  setHasChange,
  setInitialValues,
  getValues,
}) => {
  const [blockerModal, setBlockerModal] = useState(false);
  const watchFields = watch(); // Watch all form fields

  useEffect(() => {
    setInitialValues(getValues());
  }, []);
  // Function to check if any field is changed
  const isAnyFieldChanged = () => {
    const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    // Loop through dirty fields and compare with initial values
    return Object.keys(dirtyFields).some((field) => {
      let initialValue = initialValues[field];
      let currentValue = watchFields[field];

      if (dateTimeRegex.test(initialValues[field])) {
        initialValue = dayjs(initialValues[field]).format("YYYY-MM-DD");
      }
      if (currentValue === "" && initialValue === undefined) {
        return false;
      }
      // If current value is different from initial value, return true
      return currentValue !== initialValue;
    });
  };
  const values = isAnyFieldChanged();

  useEffect(() => {
    setHasChange(values);
  }, [values]);

  // UnSaved Changes Feature here
  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) =>
      hasChange && currentLocation.pathname !== nextLocation.pathname,
    [hasChange]
  );

  const blocker = unstable_useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setBlockerModal(true);
    }
  }, [blocker]);

  // Page Reload and navigation alert implementations
  useEffect(() => {
    window.onbeforeunload = function () {
      if (hasChange) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    };
  }, [hasChange]);

  const handleOnLeave = () => {
    blocker.proceed();
    setBlockerModal(false);
    setHasChange(false);
  };
  const handleOnStay = () => {
    blocker.reset();
    setBlockerModal(false);
  };
  return (
    <>
      {blockerModal ? (
        <BlockerModal
          text={
            text ||
            `Hold up! You've got unsaved changes. Are you sure you want to leave?`
          }
          open={blockerModal}
          onStay={handleOnStay}
          onLeave={handleOnLeave}
        />
      ) : null}
    </>
  );
};

export default BaseBlockerModal;
